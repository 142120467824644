import React from 'react'

import Layout from '../../components/en/Layout'
import SEO from '../../components/SEO'

import mansurProfile from '../../images/mansur-profile.jpeg'

const AboutUs = () => {
  return (
    <Layout>
      <div className="about-us">
        <SEO
          title="About Us"
          canonical={`${process.env.GATSBY_HOST_URL}/en/about-us`}
          isAlternate={true}
          alternateUrl={`${process.env.GATSBY_HOST_URL}/pt/quem-somos`}
        />

        <h1>About Us</h1>

        <div className="about-us-profile">
          <div
            className="about-us-profile-pic"
            style={{
              background: `url(${mansurProfile})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          ></div>
          <div className="about-us-profile-content">
            <h3>Mansur Peixoto</h3>

            <p>
              Entrepreneur, digital influencer and content producer with an
              impact on more than 400 thousand people per month. Student of
              Islamic history and religion, speaking Arabic, English and
              Spanish. He studied Islamic and Arab religion in Egypt and
              continues his studies in Brazil.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutUs
